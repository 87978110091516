import React, { useState } from 'react';

const HandleCheck = () => {
    const [handle, setHandle] = useState('');
    const [results, setResults] = useState({});
    const [error, setError] = useState('');

    const handleChange = (event) => {
        setHandle(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setError('');
        setResults({});

        const eventSource = new EventSource(`https://solar-backend-qa7k.onrender.com/api/check_handle?handle=${handle}`);

        eventSource.onmessage = (event) => {
            const parsedData = JSON.parse(event.data);
            setResults((prevResults) => ({
                ...prevResults,
                ...parsedData,
            }));
        };

        eventSource.onerror = (err) => {
            console.error('EventSource failed:', err);
            setError('An error occurred while checking the handle.');
            eventSource.close();
        };

        eventSource.onopen = () => {
            console.log('Connection to server opened.');
        };

        eventSource.addEventListener('end', () => {
            console.log('Stream ended.');
            console.log('Closing connection after delay.');
            eventSource.close();
        });

        eventSource.onclose = () => {
            console.log('Connection to server closed.');
        };

        return () => {
            if (eventSource) {
                eventSource.close();
                console.log('EventSource closed on component unmount.');
            }
        };
    };

    return (
        <div>
            <h1>Check Social Media Handle Availability</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={handle}
                    onChange={handleChange}
                    placeholder="Enter handle"
                />
                <button type="submit">Check Handle</button>
            </form>
            <h2>Handle Check Results for "{handle}"</h2>
            {error && <p className="error">{error}</p>}
            <ul>
                {Object.entries(results).map(([platform, status]) => (
                    <li key={platform}>
                        {platform}: {status === 'Taken' ? 'Taken' : 'Available'}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default HandleCheck;
